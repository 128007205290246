import * as React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BackgroundSection from "../components/Globals/BackgroundSection"
import Menu from "../components/Home/Menu"

const MenuPage = ({ data }) => {
  const image = getImage(data.image.childImageSharp.gatsbyImageData)
  return (
    <Layout>
      <Seo title="Menu" />
      <BackgroundSection
        img={image}
        title="Menu"
        description="The mission is simple: serve delicious, affordable food that guests will want to return to week after week."
        styleClass="menu-background background-images"
      />
      {/* <div className="container">
        <div className="row text-center my-3 ">
          <h1 className=" text-uppercase text-center display-6 font-weight-bold hero-text-lightGrey">
            Click to see PDF
          </h1>
          <a
            href={togo}
            download
            className="text-decoration-none hero-text-red"
          >
            Crab Station Odessa Menu
            <BiFoodMenu className="menu-icon" />
          </a>
        </div>
      </div> */}

      {/* <ul>
        {data.allFile.edges.map((file, index) => {
          return (
            <l1 key={`pdf-${index}`}>
              <a href={file.node.publicURL} downloads>
                {file.node.name}
              </a>
            </l1>
          )
        })}
      </ul> */}
      <Menu items={data.menu} />
    </Layout>
  )
}

export const query = graphql`
  {
    image: file(relativePath: { eq: "seafood-ice.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2000
          quality: 50
          placeholder: NONE
          blurredOptions: { width: 100 }
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }
    menu: allContentfulMenuItem {
      edges {
        node {
          id
          title
          description {
            description
          }
          price
          category
          categoryOrder
          subcategory
          subCategoryOrder
          subCategoryTitle
        }
      }
    }
  }
`

export default MenuPage
