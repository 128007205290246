import React, { Component } from "react"
import Title from "../Globals/Title"
import {
  documentToReactComponents,
  renderRichText,
} from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import Logo2 from "../../images/svg/CSlogo2.inline.svg"

import frontMenu from "../../../static/crab_station_menu.pdf"
import backMenu from "../../../static/crab_station_back_menu.pdf"

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

// const options = {
//   renderMark: {
//     [MARKS.BOLD]: text => <Bold>{text}</Bold>,
//   },
//   renderNode: {
//     [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
//     [BLOCKS.EMBEDDED_ASSET]: node => {
//       return (
//         <>
//           <h2>Embedded Asset</h2>
//           <pre>
//             <code>{JSON.stringify(node, null, 2)}</code>
//           </pre>
//         </>
//       )
//     },
//   },
// }
// ​
// renderRichText(node.bodyRichText, options)

const getCategories = items => {
  let tempItems = items.map(items => {
    return items.node.category
  })
  let tempCategories = new Set(tempItems)
  let categories = Array.from(tempCategories)
  // categories = ["all", ...categories]
  // categories = [...categories]

  let tempCategoryArray = items.map(items => {
    return items.node
  })
  let tempSortCatArray = new Set(tempCategoryArray)
  let categoryUnsortedOrder = Array.from(tempSortCatArray)
  let categorySortedOrder = [...categoryUnsortedOrder]

  // remove duplicates in category
  let sortItems = categorySortedOrder.filter(
    (tempCategoryOrder, index, self) =>
      self.findIndex(
        t =>
          t.categoryOrder === tempCategoryOrder.categoryOrder &&
          t.category === tempCategoryOrder.category
      ) === index
  )
  // sort unduplicated categories by categoryOrder
  const tempSortedItems = [...sortItems].sort((a, b) =>
    a.categoryOrder > b.categoryOrder ? 1 : -1
  )
  let sortedItems = tempSortedItems.map(items => {
    return items.category
  })
  categories = [...sortedItems]

  return categories
}
const getSubCategories = items => {
  let tempSubItems = items.map(items => {
    return items.node.subcategory
  })
  let TempSubCategories = new Set(tempSubItems)
  let subCategories = Array.from(TempSubCategories)
  let arr = new Array()
  // let arr2 = new Array()
  // for (var i = subCategories.length; i > 0; i--) {
  // for (var i = 0; i < subCategories.length; i++) {
  //   if (subCategories[i] === "small") {
  //     console.log(subCategories[i])
  //     arr.splice(0, 1, subCategories[i])
  //     // arr2.push(subCategories[i])
  //   }
  //   if (subCategories[i] === "medium") {
  //     console.log(subCategories[i])
  //     arr.splice(1, 1, subCategories[i])
  //     // arr2.push(subCategories[i])
  //   }
  //   if (subCategories[i] === "large") {
  //     // } else {
  //     console.log(subCategories[i])
  //     arr.splice(2, 1, subCategories[i])
  //     // arr2.push(subCategories[i])
  //   }
  // }
  arr = ["small", "medium", "large"]
  subCategories = [...arr]
  return subCategories
}

// const renderDocument = document => {
//   const Text = ({ children }) => <p>{children}</p>

//   const options = {
//     renderNode: {
//       [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
//     },
//     renderText: text =>
//       text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),

//     // renderText: text =>
//     //   text.split("\n").map((t, i) =>
//     //     i > 0 ? (
//     //       <React.Fragment key={i}>
//     //         <br />
//     //         {t}
//     //       </React.Fragment>
//     //     ) : (
//     //       t
//     //     )
//     //   ),
//   }
//   return documentToReactComponents(document, options)
// }

export default class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      click: false,
      subCategorize: false,
      subCat: "",
      items: props.items.edges,
      menuItems: props.items.edges,
      categories: getCategories(props.items.edges),
      subCategories: getSubCategories(props.items.edges),
    }
  }
  handleClick = () => {
    this.setState(() => {
      return { click: true }
    })
  }
  handleItems = category => {
    let tempItems = [...this.state.items]
    let items = tempItems.filter(({ node }) => node.category === category)
    let tempArray = new Set(items)
    let subCategoryUnsortedOrder = Array.from(tempArray)
    let subCategorySortedOrder = [...subCategoryUnsortedOrder]

    const tempSortedItems = [...subCategorySortedOrder].sort((a, b) =>
      a.node.subCategoryOrder > b.node.subCategoryOrder ? 1 : -1
    )
    subCategorySortedOrder = [...tempSortedItems]

    this.setState(() => {
      return { menuItems: subCategorySortedOrder, subCat: category }
    })

    // if (category === "all") {
    //   this.setState(() => {
    //     return { menuItems: tempItems }
    //   })
    // } else {
    //   let items = tempItems.filter(({ node }) => node.category === category)
    //   this.setState(() => {
    //     return { menuItems: items }
    //   })
    // }
  }
  handleSubCategories = subCategory => {
    let tempSubItems = [...this.state.items]
    let subItems = tempSubItems.filter(
      ({ node }) => node.subcategory === subCategory
    )
    this.setState(() => {
      return { menuItems: subItems }
    })
    console.log("menuItems from Handle = ", subItems)
  }

  render() {
    if (this.state.items.length > 0) {
      return (
        <section className="menu">
          <div className="container">
            <div className="my-5 hero-text-xred">
              <Title title="Best of our Menu!" />
            </div>
            <div className="d-flex justify-content-center p-3">
              <a
                href={frontMenu}
                target="_blank"
                rel="noopener noreferrer"
                // download
                className="text-decoration-none text-uppercase menu-pdf-btn "
              >
                Download PDF<span className="mx-4 hero-text-xred ">| </span>
              </a>

              <a
                href={backMenu}
                target="_blank"
                rel="noopener noreferrer"
                // download
                className="text-decoration-none text-uppercase menu-pdf-btn "
              >
                Market Price
              </a>
            </div>

            {/* categories */}

            <div className="row mb-1">
              <div className="col-12 mx-auto py-4 m-4 text-center ">
                {this.state.categories.map((category, index) => {
                  return (
                    <button
                      type="button"
                      key={index}
                      className="btn menu-categories-btn text-uppercase m-2"
                      onClick={() => {
                        this.handleItems(category)
                        this.handleClick()
                      }}
                    >
                      {category}
                    </button>
                  )
                })}
              </div>
            </div>
            {/* items */}
            {this.state.click === false ? (
              <div className="d-flex mx-auto justify-content-center">
                <Logo2 />
              </div>
            ) : this.state.subCat === "Sacks" ? (
              <div className="row mb-1">
                <div className="col-12 mx-auto py-4">
                  <h3 className="menu-current-category-title text-uppercase">
                    {"- "}
                    {this.state.subCat}
                    {" -"}
                  </h3>
                  <div className="menu-border border-danger m-4">
                    {this.state.subCategories.map((subCategoryx, index) => {
                      return (
                        <div key={index}>
                          <h5 className="menu-text-color text-uppercase mx-auto mt-4 text-center menu-subcategory-title ">
                            {subCategoryx}
                          </h5>
                          {this.state.menuItems.map(({ node }) => {
                            if (subCategoryx === node.subcategory) {
                              // console.log(
                              //   `index is: ${index} AND INSIDE index is: ${index} AND subCategoryx is ${subCategoryx} AND item is: ${node.title} AND node.subcategory is: ${node.subcategory} AND node.subCategoryOrder is: ${node.subCategoryOrder}`
                              // )
                              return (
                                <div
                                  key={node.id}
                                  className="col-10 col-md-12 my-3 d-flex mx-auto menu-items mb-5"
                                >
                                  {/* item text */}
                                  <div className="flex-grow-1 px-3 ">
                                    <div className="d-flex justify-content-center text-center">
                                      <h5 className="mb-0 menu-text-color menu-item-title text-uppercase">
                                        <small>{node.title}</small>
                                      </h5>
                                    </div>
                                    <div className="d-flex justify-content-center menu-item-description ">
                                      <p className="text-muted text-center">
                                        <small>
                                          {node.description.description}
                                        </small>
                                      </p>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                      <p className="mb-0 menu-text-color">
                                        <small className="menu-items-price">
                                          ${node.price}
                                        </small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                            // return(
                            //   {subCategoryx === node.subcategory ? (
                            //     <div
                            //       key={node.id}
                            //       className="col-10 col-md-12 my-3 d-flex mx-auto menu-items mb-5"
                            //     >
                            //       {/* item text */}
                            //       <div className="flex-grow-1 px-3 ">
                            //         <div className="d-flex justify-content-center text-center">
                            //           <h5 className="mb-0 menu-text-color menu-item-title text-uppercase">
                            //             <small>{node.title}</small>
                            //           </h5>
                            //         </div>
                            //         <div className="d-flex justify-content-center menu-item-description ">
                            //           <p className="text-muted text-center">
                            //             <small>
                            //               {node.description.description}
                            //             </small>
                            //           </p>
                            //         </div>
                            //         <div className="d-flex justify-content-center">
                            //           <p className="mb-0 menu-text-color">
                            //             <small className="menu-items-price">
                            //               ${node.price}
                            //             </small>
                            //           </p>
                            //         </div>
                            //       </div>
                            //     </div>
                            //   ) : null}
                            // )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="row mb-1">
                <div className="col-12 mx-auto py-4">
                  <h3 className="menu-current-category-title text-uppercase">
                    {"- "}
                    {this.state.subCat}
                    {" -"}
                  </h3>
                  <div className="menu-border rounded border-danger m-4">
                    {this.state.menuItems.map(({ node }) => {
                      return (
                        <div
                          key={node.id}
                          className="col-10 col-md-12 d-flex mx-auto my-4"
                        >
                          {/* item text */}
                          <div className="flex-grow-1 ">
                            <div className="d-flex justify-content-center text-center">
                              <h5 className="mb-0 menu-text-color text-uppercase menu-item-title">
                                <small>{node.title}</small>
                              </h5>
                            </div>

                            {node.description.description === "none" ? (
                              <div className="d-flex justify-content-center menu-item-description ">
                                <p className="text-muted text-center"></p>
                              </div>
                            ) : (
                              <div className="d-flex justify-content-center menu-item-description ">
                                <p className="text-muted text-center">
                                  <small>
                                    {node.description.description}
                                    {/* {renderDocument(
                                      node.description.description
                                    )} */}
                                  </small>
                                </p>
                              </div>
                            )}
                            {node.price === 0 ? (
                              <div className="d-flex justify-content-center">
                                <p className="mb-0 menu-text-color"></p>
                              </div>
                            ) : (
                              <div className="d-flex justify-content-center">
                                <p className="mb-0 menu-text-color">
                                  <small className="menu-items-price">
                                    ${node.price}
                                  </small>
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
            {/* Consumer Advisory */}
            <div className="menu-consumer-advisory rounded border-danger m-4">
              <p>
                **Consuming raw or undercooked meats, poultry, seafood,
                shellfish, or eggs may increase your risk of foodborne illness,
                especially if you have certain medical conditions.{" "}
              </p>
            </div>
          </div>
        </section>
      )
    } else {
      return (
        <section className="menu py-5">
          <div className="container">
            <Title title="Best of Our Menu" />
            <div className="row">
              <div className="col-10 col-sm-6 mx-auto text-center text-capitalize">
                <h1>There are no items to display.</h1>
              </div>
            </div>
          </div>
        </section>
      )
    }
  }
}
